var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default-communications-container"},[_c('vs-row',{staticClass:"items-start"},[_c('vs-col',{staticClass:"pr-5",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('vx-card',{staticClass:"custom-vx-card"},[_c('tabs',{attrs:{"tabs-list":_vm.tabs,"defaultSelect":_vm.selectedPage},on:{"handleEvent":function($event){_vm.selectedPage = $event}}}),(_vm.activeTab === 'EmailTemplates')?_c('vs-table',{staticClass:"mt-5 pay-activity-table stripes",attrs:{"data":_vm.emailTemplates},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":tr.templateId}},[_vm._v(" "+_vm._s(tr.templateId)+" ")]),_c('vs-td',{attrs:{"data":tr.productType}},[_vm._v(" "+_vm._s(tr.productType)+" ")]),_c('vs-td',{attrs:{"data":tr.templateCategory}},[_vm._v(" "+_vm._s(tr.templateCategory)+" ")]),_c('vs-td',{attrs:{"data":tr}},[_c('u',[_c('a',{on:{"click":function($event){return _vm.handleEdit(tr._id, tr.templateType)}}},[_vm._v("Edit")])])]),_c('vs-td',{attrs:{"data":tr}},[_c('u',[_c('a',{on:{"click":function($event){return _vm.handlePushDefault(tr._id, tr.templateType)}}},[_vm._v("Push default")])])])],1)})}}],null,false,778863645)},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"width":"20%"}},[_c('div',{staticClass:"boxBody"},[_c('div',{staticClass:"combo-box",on:{"click":function($event){return _vm.changeFilter('templateId')}}},[_c('label',{staticClass:"m-0 p-0 text-base font-medium"},[_vm._v("ID")]),(!_vm.filtersHidden)?_c('div',{staticClass:"icon-combo"},[_c('vs-icon',{class:_vm.searchParam.sortBy === 'templateId' && _vm.searchParam.sortDir === 'asc' ? 'dark' : '',attrs:{"icon":"arrow_drop_up"}}),_c('vs-icon',{class:_vm.searchParam.sortBy === 'templateId' && _vm.searchParam.sortDir === 'desc' ? 'dark' : '',attrs:{"icon":"arrow_drop_down"}})],1):_vm._e()]),(_vm.searchParam.templateId)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'templateId')}}},[_vm._v("X")]):_vm._e(),(!_vm.filtersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.searchParam.templateId,
                    textBlue: _vm.searchParam.templateId,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.searchParam.templateId),callback:function ($$v) {_vm.$set(_vm.searchParam, "templateId", $$v)},expression:"searchParam.templateId"}}):_vm._e()],1)]),_c('vs-th',{attrs:{"width":"30%"}},[_c('div',{staticClass:"boxBody"},[_c('div',{staticClass:"combo-box",on:{"click":function($event){return _vm.changeFilter('productType')}}},[_c('label',{staticClass:"m-0 p-0 text-base font-medium"},[_vm._v("Product Type")]),(!_vm.filtersHidden)?_c('div',{staticClass:"icon-combo"},[_c('vs-icon',{class:_vm.searchParam.sortBy === 'productType' && _vm.searchParam.sortDir === 'asc' ? 'dark' : '',attrs:{"icon":"arrow_drop_up"}}),_c('vs-icon',{class:_vm.searchParam.sortBy === 'productType' && _vm.searchParam.sortDir === 'desc' ? 'dark' : '',attrs:{"icon":"arrow_drop_down"}})],1):_vm._e()]),(_vm.searchParam.productType)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'productType')}}},[_vm._v("X")]):_vm._e(),(!_vm.filtersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.searchParam.productType,
                    textBlue: _vm.searchParam.productType,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.searchParam.productType),callback:function ($$v) {_vm.$set(_vm.searchParam, "productType", $$v)},expression:"searchParam.productType"}}):_vm._e()],1)]),_c('vs-th',{attrs:{"width":"30%"}},[_c('div',{staticClass:"boxBody"},[_c('div',{staticClass:"combo-box",on:{"click":function($event){return _vm.changeFilter('templateType')}}},[_c('label',{staticClass:"m-0 p-0 text-base font-medium"},[_vm._v("Template Type")]),(!_vm.filtersHidden)?_c('div',{staticClass:"icon-combo"},[_c('vs-icon',{class:_vm.searchParam.sortBy === 'templateType' && _vm.searchParam.sortDir === 'asc' ? 'dark' : '',attrs:{"icon":"arrow_drop_up"}}),_c('vs-icon',{class:_vm.searchParam.sortBy === 'templateType' && _vm.searchParam.sortDir === 'desc' ? 'dark' : '',attrs:{"icon":"arrow_drop_down"}})],1):_vm._e()]),(_vm.searchParam.templateType)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'templateType')}}},[_vm._v("X")]):_vm._e(),(!_vm.filtersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.searchParam.templateType,
                    textBlue: _vm.searchParam.templateType,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.searchParam.templateType),callback:function ($$v) {_vm.$set(_vm.searchParam, "templateType", $$v)},expression:"searchParam.templateType"}}):_vm._e()],1)]),_c('vs-th'),_c('vs-th',{staticClass:"filter-cross",class:{ toggle: !_vm.filtersHidden }},[_c('filter-icon',{attrs:{"size":"1.5x","fill":_vm.filterIconColor.fill,"stroke":_vm.filterIconColor.stroke},on:{"click":_vm.toggleFilter}})],1)],1)],2):_vm._e(),(_vm.activeTab === 'SmsTemplates')?_c('vs-table',{staticClass:"mt-5",attrs:{"data":_vm.smsTemplates},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":tr.templateId}},[_vm._v(" "+_vm._s(tr.templateId)+" ")]),_c('vs-td',{attrs:{"data":tr.productType}},[_vm._v(" "+_vm._s(tr.productType)+" ")]),_c('vs-td',{attrs:{"data":tr.templateCategory}},[_vm._v(" "+_vm._s(tr.templateCategory)+" ")]),_c('vs-td',{attrs:{"data":tr}},[_c('u',[_c('a',{on:{"click":function($event){return _vm.handleEdit(tr._id, tr.templateType)}}},[_vm._v("Edit")])])]),_c('vs-td',{attrs:{"data":tr}},[_c('u',[_c('a',{on:{"click":function($event){return _vm.handlePushDefault(tr._id, tr.templateType)}}},[_vm._v("Push default")])])])],1)})}}],null,false,778863645)},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"width":"20%"}},[_c('div',{staticClass:"boxBody"},[_c('div',{staticClass:"combo-box",on:{"click":function($event){return _vm.smsChangeFilter('templateId')}}},[_c('label',{staticClass:"m-0 p-0 text-base font-medium"},[_vm._v("ID")]),(!_vm.smsFiltersHidden)?_c('div',{staticClass:"icon-combo"},[_c('vs-icon',{class:_vm.smsSearchParam.sortBy === 'templateId' && _vm.smsSearchParam.sortDir === 'asc' ? 'dark' : '',attrs:{"icon":"arrow_drop_up"}}),_c('vs-icon',{class:_vm.smsSearchParam.sortBy === 'templateId' && _vm.smsSearchParam.sortDir === 'desc' ? 'dark' : '',attrs:{"icon":"arrow_drop_down"}})],1):_vm._e()]),(_vm.smsSearchParam.templateId)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'templateId')}}},[_vm._v("X")]):_vm._e(),(!_vm.smsFiltersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.smsSearchParam.templateId,
                    textBlue: _vm.smsSearchParam.templateId,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.smsSearchParam.templateId),callback:function ($$v) {_vm.$set(_vm.smsSearchParam, "templateId", $$v)},expression:"smsSearchParam.templateId"}}):_vm._e()],1)]),_c('vs-th',{attrs:{"width":"30%"}},[_c('div',{staticClass:"boxBody"},[_c('div',{staticClass:"combo-box",on:{"click":function($event){return _vm.smsChangeFilter('productType')}}},[_c('label',{staticClass:"m-0 p-0 text-base font-medium"},[_vm._v("Product Type")]),(!_vm.smsFiltersHidden)?_c('div',{staticClass:"icon-combo"},[_c('vs-icon',{class:_vm.smsSearchParam.sortBy === 'productType' && _vm.smsSearchParam.sortDir === 'asc' ? 'dark' : '',attrs:{"icon":"arrow_drop_up"}}),_c('vs-icon',{class:_vm.smsSearchParam.sortBy === 'productType' && _vm.smsSearchParam.sortDir === 'desc' ? 'dark' : '',attrs:{"icon":"arrow_drop_down"}})],1):_vm._e()]),(_vm.smsSearchParam.productType)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'productType')}}},[_vm._v("X")]):_vm._e(),(!_vm.smsFiltersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.smsSearchParam.productType,
                    textBlue: _vm.smsSearchParam.productType,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.smsSearchParam.productType),callback:function ($$v) {_vm.$set(_vm.smsSearchParam, "productType", $$v)},expression:"smsSearchParam.productType"}}):_vm._e()],1)]),_c('vs-th',{attrs:{"width":"30%"}},[_c('div',{staticClass:"boxBody"},[_c('div',{staticClass:"combo-box",on:{"click":function($event){return _vm.smsChangeFilter('templateType')}}},[_c('label',{staticClass:"m-0 p-0 text-base font-medium"},[_vm._v("Template Type")]),(!_vm.smsFiltersHidden)?_c('div',{staticClass:"icon-combo"},[_c('vs-icon',{class:_vm.smsSearchParam.sortBy === 'templateType' && _vm.smsSearchParam.sortDir === 'asc' ? 'dark' : '',attrs:{"icon":"arrow_drop_up"}}),_c('vs-icon',{class:_vm.smsSearchParam.sortBy === 'templateType' && _vm.smsSearchParam.sortDir === 'desc' ? 'dark' : '',attrs:{"icon":"arrow_drop_down"}})],1):_vm._e()]),(_vm.smsSearchParam.templateType)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'templateType')}}},[_vm._v("X")]):_vm._e(),(!_vm.smsFiltersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.smsSearchParam.templateType,
                    textBlue: _vm.smsSearchParam.templateType,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.smsSearchParam.templateType),callback:function ($$v) {_vm.$set(_vm.smsSearchParam, "templateType", $$v)},expression:"smsSearchParam.templateType"}}):_vm._e()],1)]),_c('vs-th',{staticClass:"filter-cross",class:{ toggle: !_vm.smsFiltersHidden }},[_c('filter-icon',{attrs:{"size":"1.5x","fill":_vm.filterIconColor.fill,"stroke":_vm.filterIconColor.stroke},on:{"click":_vm.smsToggleFilter}})],1),_c('vs-th')],1)],2):_vm._e()],1)],1)],1),_c('vs-popup',{staticClass:"close-icon-hidden",attrs:{"title":"Overwrite comms templates?","header-icon":"warning","active":_vm.pushDefaultPopUp},on:{"update:active":function($event){_vm.pushDefaultPopUp=$event}}},[_c('div',{staticClass:"w-full text-justify"},[_c('p',{staticClass:"flex mb-2"},[_vm._v("You're about to overwrite this comms template on ALL products that are enabled to use it - are you sure?")])]),_c('div',{staticClass:"popup-action-btn-container flex justify-end"},[_c('vs-button',{attrs:{"size":"large","action-btn":""},on:{"click":_vm.forceDefaultComms}},[_vm._v("Confirm")]),_c('vs-button',{staticClass:"ml-6 md:ml-10",attrs:{"size":"large","type":"flat"},on:{"click":_vm.clearPushDefault}},[_vm._v("Cancel")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }